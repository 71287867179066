import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
// import Icon from 'react-native-vector-icons/MaterialCommunityIcons'; // Choose the appropriate icon set
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const HomeButton = ({ onPress } : {onPress : any}) => {
    return (
        <TouchableOpacity onPress={onPress} style={styles.buttonHome}>
            <MaterialCommunityIcons name="home" size={30} color="#fff" />
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    buttonHome: {
        flex : 1,
        marginLeft:5,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
        padding: 5,
        backgroundColor: '#007AFF', // You can adjust the color to match your theme
        borderRadius: 5,
    },
    text: {
        marginLeft: 5,
        color: '#fff',
        fontSize: 16,
    },
});

export default HomeButton;
