import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'; // Ensure correct icon set is imported

const LogoutButton = ({ onPress } : {onPress : any}) => {
    return (
        <TouchableOpacity onPress={onPress} style={styles.button}>
            <Icon name="logout" size={30} color="#fff" />
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    button: {
        flex: 1,
        marginLeft: 5,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
        padding: 5,
        backgroundColor: 'red', // Adjust color as needed
        borderRadius: 5,
    },
    text: {
        marginLeft: 5,
        color: '#fff',
        fontSize: 16,
    },
});

export default LogoutButton;
