import React, { createContext, useContext, useState } from 'react';
import axios, { AxiosResponse } from "axios";
// Define the shape of your default headers
interface DefaultHeaders {
    [key: string]: string;
}

type METHODS = "get" | "post" | "delete" | "put" | "patch";

// Define the shape of your global state
interface GlobalState {
    global_url: string;
    setnewUrl: (value: string) => void;
    defaultHeaders: DefaultHeaders; // Add default headers here
    auth: string;
    setNewAuth: (value: string) => void;
    token: string;
    setToken: (value: string) => void;
    user_id: string;
    setUser_id: (value: string) => void;
    fullname: string;
    setFullName: (value: string) => void;
    loginTime: string;
    setLoginTime: (value: string) => void;
    formatDateLoad: Function;
    formatDateSave: Function;
    formatTimeSave: Function;
    formatTimeLoad: Function;
    fetch: Function;
}

// Create a context for the global state
const GlobalStateContext = createContext<GlobalState | undefined>(undefined);

// Create a provider component to wrap your app
export const GlobalStateProvider = ({
    children,
}: {
    children?: React.ReactNode;
}) => {
    const [global_url, setnewUrl] = useState<string>(
       'https://www.godlinkit.com/api',
    );

    // const [global_url, setnewUrl] = useState<string>("http://127.0.0.1:8000/api");

    // const [global_url, setnewUrl] = useState<string>("http://kokotsko.local/api")

    const defaultHeaders: DefaultHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    };

    const [auth, setNewAuth] = useState<string>('');

    const [token, setToken] = useState<string>('');

    const [user_id, setUser_id] = useState<string>('');

    const [fullname, setFullName] = useState<string>('');

    const [loginTime, setLoginTime] = useState<string>('');
    const formatDateLoad = (date: string) => {
        if (date) {
            const year = date.split('-')[0];
            const month = date.split('-')[1];
            const day = date.split('-')[2];

            return `${day}.${month}.${year}`;
        } else {
            return date;
        }
    };

    const formatTimeSave = (time : string) => {
        let parts = time.split(':');
        let hours = parts[0].padStart(2, '0');
        let minutes = parts[1].padStart(2, '0');
        let seconds = '00';
        return `${hours}:${minutes}:${seconds}`;
    };

    const formatTimeLoad = (time : string) => {
        return time.split(":").slice(0,2).join(":");
    };

    const formatDateSave = (date: string) => {
        if (date) {
            const day = date.split('.')[0];
            const month = date.split('.')[1];
            const year = date.split('.')[2];

            return `${year}-${month}-${day}`;
        } else {
            return date;
        }
    };

    // @ts-ignore
    const fetch = async (method : METHODS, url : string, body? : any ) : Promise<AxiosResponse<any> | string> => {
        const correctUrl = `${global_url}/${url}`;

        try {
            let response: AxiosResponse<any>;

            switch (method) {
                case "get":
                case "delete":
                    response = await axios[method](correctUrl, {
                        headers: { ...defaultHeaders, "Token-Auth": token }
                    });
                    break;
                case "patch":
                case "put":
                case "post":
                    response = await axios[method](correctUrl, body, {
                        headers: { ...defaultHeaders, "Token-Auth": token }
                    });
                    break;
                default:
                    return "wrong method";
            }

            return response;
        } catch (error) {
            // Log the error and rethrow or return a suitable error message
            console.error('Fetch error:', error);
            throw error;
        }
    };

    const state: GlobalState = {
        global_url,
        setnewUrl,
        defaultHeaders,
        auth,
        setNewAuth,
        token,
        setToken,
        user_id,
        setUser_id,
        fullname,
        setFullName,
        loginTime,
        setLoginTime,
        formatDateLoad,
        formatDateSave,
        formatTimeSave,
        formatTimeLoad,
        fetch,
    };

    return (
        <GlobalStateContext.Provider value={state}>
            {children}
        </GlobalStateContext.Provider>
    );
};

// Create a custom hook to access the global state
export const useGlobalState = (): GlobalState => {
    const context = useContext(GlobalStateContext);
    if (context === undefined) {
        throw new Error(
            'useGlobalState must be used within a GlobalStateProvider',
        );
    }
    return context;
};
