import React, { useState, useEffect } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { getPlatformComponent } from '@components/V1/PlatformRedirector';
import { GlobalStateProvider } from '@components/V1/GlobalStateProvider';
import LogoutBtn from './components/smallComponents/LogoutBtn';
import HomeButton from './components/smallComponents/HomeBtn';
import { companyOptions } from '@components/V1/Objednavky/typeObjednavky';
import * as Font from 'expo-font';
import { MaterialCommunityIcons } from '@expo/vector-icons'; // Ensure this is imported correctly

const Stack = createNativeStackNavigator();

const combinedOptions = ({ navigation }) => ({
    headerRight: () => (
        <View style={{ flexDirection: "row" }}>
            <LogoutBtn onPress={() => {
                navigation.navigate('Login');
            }} />
            <HomeButton
                onPress={() => {
                    navigation.navigate('Home');
                }}
            />
        </View>
    ),
    headerShown: true
});

const screenConfigurations = [
    { name: "Login", component: "Login.tsx", options: { headerShown: false } },
    { name: "Home", component: "HomePage.tsx", options: combinedOptions },
    { name: "Zamestnanci", component: 'Zamestnanci/Zamestnanci.tsx', options: combinedOptions },
    { name: "Zamestnanec Detail", component: 'Zamestnanci/ZamestnanecDetail.tsx', options: combinedOptions },
    { name: "Zamestnanec edit", component: 'Zamestnanci/ZamestnanecEdit.tsx', options: combinedOptions },
    { name: "Klienti", component: "Klienti/Klienti.tsx", options: combinedOptions },
    { name: "Klient detail", component: "Klienti/KlientDetail.tsx", options: combinedOptions },
    { name: "Klient edit", component: "Klienti/KlientiEdit.tsx", options: combinedOptions },
    { name: "Servisy", component: "Servisy/Servisy.tsx", options: combinedOptions },
    { name: "Servis detail", component: "Servisy/ServisDetail.tsx", options: combinedOptions },
    { name: "Servis edit", component: "Servisy/ServisEdit.tsx", options: combinedOptions },
    { name: "Obhliadky", component: "Obhliadky/Obhliadky.tsx", options: combinedOptions },
    { name: "Obhliadka detail", component: "Obhliadky/ObhliadkyDetail.tsx", options: combinedOptions },
    { name: "Obhliadka položky", component: "Obhliadky/ObhliadkaPolozky.tsx", options: combinedOptions },
    { name: "Obhliadka položky edit", component: "Obhliadky/ObhliadkaPolozkyEdit.tsx", options: combinedOptions },
    { name: "Obhliadka edit", component: "Obhliadky/ObhliadkaEdit.tsx", options: combinedOptions },
    { name: "Položky", component: 'Polozky/PolozkyList.tsx', options: combinedOptions },
    { name: "Položky edit", component: 'Polozky/PolozkyEdit.tsx', options: combinedOptions },
    { name: "Obnovenie hesla", component: "PassFormComponent", options: combinedOptions },
    { name: "DashboardNew", component: "Dashboard.tsx", options: combinedOptions },
    { name: "Produkty", component: "Produkty/ProductsMenu.tsx", options: combinedOptions },
    { name: "Siete", component: "Produkty/Siete/Basic/ProductNets.tsx", options: combinedOptions },
    { name: "Siete edit", component: "Produkty/Siete/Basic/ProductNetsEdit.tsx", options: combinedOptions },
    { name: "Siete detail", component: "Produkty/Siete/Basic/ProductNetsDetail.tsx", options: combinedOptions },
    { name: 'Siete parametre', component: "Produkty/Siete/Basic/ProductsNetParametreList.tsx", options: combinedOptions },
    { name: 'Siete parametre edit', component: "Produkty/Siete/Basic/ProductNetsParametreEdit.tsx", options: combinedOptions },
    { name: 'Siete WRO', component: "Produkty/Siete/Advanced/ProductSieteWro.tsx", options: combinedOptions },
    { name: 'Siete Wro detail', component: 'Produkty/Siete/Advanced/ProductSieteWroDetail.tsx', options: combinedOptions },
    { name: 'Siete Wro edit', component: 'Produkty/Siete/Advanced/ProductSieteWroEdit.tsx', options: combinedOptions },
    { name: 'Siete Wro parametre', component: 'Produkty/Siete/Advanced/ProductsSieteWroParametreList.tsx', options: combinedOptions },
    { name: 'Siete Wro parametre edit', component: 'Produkty/Siete/Advanced/ProductSieteParametreEdit.tsx', options: combinedOptions },
    { name: "Žalúzie", component: "Produkty/Zaluzie/Basic/ProductBlinds.tsx", options: combinedOptions },
    { name: "Žalúzie detail", component: "Produkty/Zaluzie/Basic/ProductsBlindsDetail.tsx", options: combinedOptions },
    { name: "Žalúzie edit", component: "Produkty/Zaluzie/Basic/ProductBlindsEdit.tsx", options: combinedOptions },
    { name: "Žalúzie parametre", component: "Produkty/Zaluzie/Basic/ProductBlindsParametreList.tsx", options: combinedOptions },
    { name: "Žalúzie parametre edit", component: "Produkty/Zaluzie/Basic/ProductBlindsParametreEdit.tsx", options: combinedOptions },
];

const App = () => {
    const [fontsLoaded, setFontsLoaded] = useState(false);

    useEffect(() => {
        async function loadAllFonts() {
            const test = await Font.loadAsync({
                ...MaterialCommunityIcons.font,
            });
            setFontsLoaded(true);
            console.log(test)
        }

        loadAllFonts();
    }, []);

    if (!fontsLoaded) {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" />
            </View>
        );
    }

    return (
        <GlobalStateProvider>
            <NavigationContainer>
                <Stack.Navigator initialRouteName="Login">
                    {screenConfigurations.map((config, index) => {
                        const LazyComponent = React.lazy(() => getPlatformComponent(config.component).then(module => ({ default: module })));

                        return (
                            <Stack.Screen
                                key={index}
                                name={config.name}
                                options={config.options}
                                component={(props) => (
                                    <React.Suspense fallback={<div>Loading...</div>}>
                                        <LazyComponent {...props} />
                                    </React.Suspense>
                                )}
                            />
                        );
                    })}
                </Stack.Navigator>
            </NavigationContainer>
        </GlobalStateProvider>
    );
};

export default App;
