import { Platform } from 'react-native';
import {ComponentType} from "react";

export const getPlatformComponent = async (subpath : string) => {
    let component : ComponentType;
    switch (Platform.OS){
        case "web":
            console.log(`./components/V1/web/${subpath}`)
            component = await import(`./web/${subpath}`).then(mod => mod.default);
            break;
        case "android":
            component = await import(`./android/${subpath}`).then(mod => mod.default);
            break;
        case "ios":
            component = await import(`./ios/${subpath}`).then(mod => mod.default);
            break;
        default:
            break;
    }
    return component
}